.search {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 13px;

    &.light {
        input {
            color: var(--text);
        }
    }

    &.dark {
        input {
            color: #fff;
        }
    }

    input {
        max-width: 140px;
        width: 100%;
    }

    label {
        font-size: 1rem;
        line-height: 1;
        color: var(--highlight);
    }
}

.control {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    color: var(--text);
    position: relative;

    i {
        color: var(--highlight);
        font-size: 16px;
    }

    &.ltr .control_indicator {
        left: 10px;
    }

    &.rtl .control_indicator {
        right: 10px;
    }

    &_indicator {
        position: absolute;
        background: var(--highlight-invert);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 10px;
    }
}

.avatar {
    position: relative;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    &_cart {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
        background: var(--highlight);
        border-radius: 50%;
        font-size: 10px;
        color: var(--header-invert);
    }
}

.navbar {
    padding: 15px 25px;
    background: var(--widget);
    border-bottom: 1px solid var(--border);
}

.title {
    margin: 10px 0 30px;
}

.tablet {
    margin-bottom: 30px;

    .title {
        margin: 0;
    }
}

.desktop {
    margin-bottom: 40px;
}